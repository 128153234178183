import { Fragment } from 'preact';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import {
    swjGameRulesSelector,
    swjOneLinerSelector,
    swjTCTextSelector,
    swjLogoSelector
} from '../../../../../selectors/site-wide-jackpot';
import { redirectToBrowser } from '../../../../../common/wrapper-bridge-mobile';
import { isMobile } from 'react-device-detect';
import { useState } from 'preact/hooks';
require(`./styles/${process.env.APPLICATION}/style.scss`);

export const TCPopup = ({ toggleOptIn, userOptedIn, handleShowPopup, showTCPopup }) => {
    const jackpotRulesDefaultURL = `${window.location.origin.replace('launcher.', '')}/site-wide-jackpot-rules`;
    const jackpotRulesURL = useSelector(swjGameRulesSelector);
    const optedInOption = userOptedIn > 0 ? 'in' : 'out';
    const oneLiner = useSelector(swjOneLinerSelector);
    const tcDescription = useSelector(swjTCTextSelector);
    const jackpotLogo = useSelector(swjLogoSelector);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleJackpotRulesLink = (e) => {
       if (!!isMobile && !!jackpotRulesURL) {
            e.preventDefault();
            redirectToBrowser(jackpotRulesURL);
        }
    }

    const handleToggle = async () => {
        setIsDisabled(true);
        try {
            await toggleOptIn();
        } catch (error) { // promise rejected?
            console.error(error);
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <Fragment>
            {showTCPopup && createPortal(
                <div className="tc-background" onClick={handleShowPopup} />,
                document.getElementById("game-launcher-wrapper")
            )}
            <div className={`tc-wrapper ${showTCPopup !== null ? (showTCPopup ? "open" : "closed") : ""}`}>
                <div className="header">
                    <img className="jackpot-logo" src={jackpotLogo} />
                </div>

                <div className="content">
                    <span className="title">{oneLiner}</span>
                    <div className="description">
                        {tcDescription}
                        <a
                            className="jackpot-rules-link"
                            target="_blank"
                            rel="noreferrer"
                            href={(!!jackpotRulesURL && !!isMobile) ? jackpotRulesURL : jackpotRulesDefaultURL}
                            onClick={handleJackpotRulesLink}>
                            Jackpot Rules
                        </a>
                    </div>
                </div>

                <div className="footer">
                    <div className="button-field">
                        <span className={`text-opted-${optedInOption}`}>{`You're opted ${optedInOption}`}</span>
                        <button disabled={isDisabled} className={userOptedIn === true ? 'toggled' : ''} onClick={handleToggle} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
