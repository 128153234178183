import { useEffect } from 'react';
import './styles/default.scss';
import { useState } from 'preact/hooks';
import { MDS } from '@fanduel/casino-swj-consumer';
import { useSelector } from 'react-redux';
import { swjTiersSelector } from '../../../selectors/site-wide-jackpot';
import { getMDSEndpoint } from '../../../common/helpers/get-mds-endpoint';

const defaultValue = {
    userId: "163694",
    messageId: "messageid_1",
    type: "JACKPOT",
    subtype: "WIN",
    createdTs: 1720696033021,
    priority: 0,
    eventTtl: 86400,
    jackpotTierName: "MajorJackpot",
    jackpotTierId: "asd",
    amount: 50,
}

const EmptyGame = () => {
    const userId = useSelector(state => state?.session?.userId);
    const [swjInput, setSWJInput] = useState(defaultValue);
    const [messages, setMessages] = useState([]);
    const swjTiers = useSelector(swjTiersSelector);
    const { emitMockJackpotWin, initSocket } = MDS(getMDSEndpoint(), setMessages);

    const handleInputChange = e => {
        setSWJInput(prev => {
            if (!e.target.value) {
                return { ...prev, [e.target.name]: defaultValue[e.target.name] };
            }

            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleTriggerJackpotWin = () => {
        const { jackpotTierId, jackpotTierName, amount, ...rest } = swjInput;

        const obj = {
            ...rest,
            payload: JSON.stringify({
                jackpotTierName,
                jackpotTierId,
                amount,
                jackpotId: 'random'
            })
        };

        emitMockJackpotWin(obj);
    };

    useEffect(() => {
        if (swjTiers){
            defaultValue.jackpotTierId = Object?.keys(swjTiers)?.[0];
        }
        defaultValue.userId = userId;
    }, [swjTiers])

    useEffect(() => {
        if (!userId) {
            return;
        }

        initSocket(userId);

        window.top.postMessage({ type: 'GC/SET_GAME_LOADED', payload: false }, '*');
    }, [userId]);

    useEffect(() => {
        console.log({ messages });
    }, [messages]);

    const handleSpinSimulate = () => {
        handleSpinStart();
        setTimeout(() => {
            handleSpinEnd();
        }, 3000);
    };

    const handleSpinStart = () => {
        window.top.postMessage({ type: 'GC/SET_SPIN_IN_PROGRESS', payload: true }, '*')
    };

    const handleSpinEnd = () => {
        window.top.postMessage({ type: 'GC/SET_SPIN_IN_PROGRESS', payload: false }, '*')
    };

    const handleSWJError = (error) => {
        console.log(`Trigger SWJ Error: ${error}`)
        window.top.postMessage({ type: error }, '*')
    }

    return (<div className="wrapper">
        <div className="section">
            <p className="section-title"> SWJ Error Toggles</p>
            <div className="button-wrapper">
                <button onClick={() => handleSWJError('GL/SWJ_OPT_IN_ERROR')} id="swj-opt-in-error"> Opt in failure </button>
                <button onClick={() => handleSWJError('GL/SWJ_OPT_OUT_ERROR')} id="swj-opt-out-error"> Opt in sync failure </button>
                <button onClick={() => handleSWJError('GL/SWJ_SWJ_DISABLED_ERROR')} id="swj-disabled-error"> SWJ disabled </button>
                <button onClick={() => handleSWJError('GL/SWJ_GAME_ADDED_TO_SWJ_ERROR')} id="swj-game-added-error"> Game added </button>
                <button onClick={() => handleSWJError('GL/SWJ_GAME_REMOVED_TO_SWJ_ERROR')} id="swj-game-removed-error"> Game removed </button>
            </div>
        </div>
        <div className="section">
            <p className="section-title"> Game Window Toggles</p>
            <div className="button-wrapper">
                <button onClick={() => handleSpinStart()} id="spin-start"> Spin START</button>
                <button onClick={() => handleSpinEnd()} id="spin-stop"> Spin FINISHED</button>
                <button onClick={() => handleSpinSimulate()} id="spin"> Spin (3 seconds)</button>
            </div>
        </div>
        <div className="section">
            <p className="section-title"> SWJ Jackpot Toggle</p>
            <div className="button-wrapper">
                <button onClick={() => handleTriggerJackpotWin()} id="swj-trigger-win-button">Trigger SWJ Win</button>
                <div className="swj-win-data-wrapper">
                    <input name="amount" onChange={handleInputChange} placeholder="amount" defaultValue={defaultValue.amount} id="swj-trigger-win-amount" />
                    <select id="swj-trigger-dropdown">
                        <option value="option1" id="swj-trigger-option-1">MiniJackpot</option>
                        <option value="option1" id="swj-trigger-option-2">MinorJackpot</option>
                        <option value="option1" id="swj-trigger-option-3">MajorrJackpot</option>
                        <option value="option1" id="swj-trigger-option-4">MegaJackpot</option>
                    </select>
                </div>
                {/* <div id="jackpot-payload">
                    {Object.keys(swjInput).map(key => <div key={key}>{key}: {<input name={key} onChange={handleInputChange} defaultValue={defaultValue[key]} />}</div>)}
                </div> */}
            </div>
        </div>
    </div>);
};

export default EmptyGame;
