import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gameWindowExpansionStateDisabled, gameWindowExpansionStateSelector } from '../../selectors/game-window';
import { setGameWindowExpansionState } from '../../redux/actions/game-window';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from '../../assets/icons/SWJ/swj-logo.png';
// import { swjTopTierLogoUrlSelector } from '../../selectors/site-wide-jackpot';

export const MinMaxGameToggle: React.FC = () => {
    require(`./styles/fanduel-casino/style.scss`);
    const dispatch = useDispatch();

    const isOn = useSelector(gameWindowExpansionStateSelector);
    const isSpinInProgress = useSelector(state => state?.['gameWindow']?.spinInProgress);
    const disabled = useSelector(gameWindowExpansionStateDisabled);
    // // TODO use this icon once its updated in Prismic
    // const logo = useSelector(swjTopTierLogoUrlSelector);

    const handleClick = () => {
        if (!disabled && !isSpinInProgress) {
            dispatch(setGameWindowExpansionState(!isOn));
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!disabled && !isSpinInProgress && (e.key === 'Enter' || e.key === ' ')) {
            handleClick();
        }
    };

    return (
        <div
            className={`min-max-toggle ${isOn ? 'on' : 'off'} ${(disabled || isSpinInProgress) && 'disabled'}`}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-pressed={isOn}
            aria-disabled={disabled || isSpinInProgress}
        >
            <div className="icon-container">
                <div className="not-show-icon" />
                <div className="icon off-icon" style={{ backgroundImage: `url(${logo})` }}>
                    <div className="cancel-icon" />
                </div>
                <div className="icon on-icon" style={{ backgroundImage: `url(${logo})` }} />
                <div className="white-underlay" />
            </div>
        </div>
    );
};

